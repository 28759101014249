<!-- 商品管理 -->
<template>
    <div class="container">
        <div class="header" style="min-width:1300px;"><div>上架总览-{{tabList[tabIndex].name}}</div></div>
        <div style="min-width:1300px;">
            <div class="tab_menu flex">
                <div v-for="(item,index) in tabList" :key="index" @click="tab(index)" class="flex menu_item" :class="{'cur':tabIndex == index}">
                    {{item.name}}
                </div>
            </div>
            <div class="flex" style="overflow: auto;margin: 20px;background: white;">
                <div style="background: rgba(255,255,255,1);padding: 20px;" class="flex-1 flex-justify-between flex-align-center">
                    <div style="font-size:18px;line-height: 40px;color:rgba(41,41,41,1);">全部上架（{{total}}）</div>
                </div>
                <div style="width: 300px;background: rgba(255,255,255,1);padding: 20px;" class="flex-justify-between flex-align-center">
                    <el-input placeholder="请输入内容"
                            prefix-icon="el-icon-search"
                            v-model="input2"></el-input>
                </div>
            </div>
            <div style="overflow: auto;margin: 20px;background: white;">
                <div class="Sjzl-table">
                    <div v-if="list">
                        <el-table :data="list" style="width: 100%" ref="_table">
                            <el-table-column style="text-align: center" prop="date1" label="产品" width="380">
                                <template slot-scope="scope">
                                    <div class="flex">
                                        <div class="coverImg flex-justify-between">
                                            <!--<div class="background_a" style="background-image: url('../../../assets/images/bg1.jpg')">-->
                                            <!--</div>-->
                                            <img v-if="scope.row.item.fashions" :src="imgUrl+scope.row.item.fashions[0].designPhotos[0]" alt="">
                                        </div>
                                        <div class="flex-1 flex-justify-between">
                                            <h3 v-if="scope.row.item" class="text-name ellipsis2">{{scope.row.item.name}}</h3>
                                            <p v-if="scope.row.item && scope.row.item.creater" class="text-type">设计师：{{scope.row.item.creater.name}}</p>
                                            <p v-if="scope.row.item && scope.row.item.createdByComp" class="text-company">{{scope.row.item.createdByComp.name}}</p>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="历史盈利">
                                <template>
                                    <div>0</div>
                                </template>
                            </el-table-column>
                            <el-table-column width="100" label="历史销量">
                                <template>
                                    <div>0</div>
                                </template>
                            </el-table-column>
                            <el-table-column width="100" label="库存数量">
                                <template>
                                    <div>0</div>
                                </template>
                            </el-table-column>
                            <el-table-column class="shop-style" prop="date5" label="渠道">
                                <template slot-scope="scope">
                                    <el-popover placement="top" width="190" trigger="hover">
                                        <h3 style="text-align:center;font-size:14px;">打开手机淘宝扫一扫</h3>
                                        <img :src="scope.row.qrcodeUrl" alt="">
                                        <div :show="creatQRCode(scope.row)" slot="reference" class="shop-icon">
                                            <i v-if="scope.row.shop.type == 0" class="iconfont icon-Shape"></i>
                                            <i v-if="scope.row.shop.type == 1" class="iconfont icon-pinduoduo"></i>
                                        </div> 
                                    </el-popover>
                                </template>
                            </el-table-column>
                            <el-table-column class="shop-style" prop="date6" label="状态">
                                <template>
                                    <div class="shop-icon">上架</div>
                                </template>
                            </el-table-column>
                            <el-table-column class="shop-style" prop="item" label="上架单价">
                                <template slot-scope="scope">
                                    <div class="shop-icon">{{scope.row.item.price}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column class="shop-style" prop="date6" label="30天销量">
                                <template>
                                    <div>0</div>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" width="130px">
                                <template slot-scope="scope">
                                    <a v-if="scope.row.thirdItemId" :href="qianniu+scope.row.thirdItemId" target="_bank">去千牛编辑商品</a>
                                    <span @click="showShopcart(scope.row.item,0)" style="color: #1890ff;cursor: pointer;margin-top: 8px;display: inline-block;">我要采购</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div style="text-align: right;">
                    <el-pagination background layout="prev, pager, next" :current-page.sync="queryOpt.page.pageNumber" :page-size="queryOpt.page.pageCount" :total="total"></el-pagination>
                </div>

            </div>
        </div>
        <el-dialog :destroy-on-close="true" :title="popTitle" :visible.sync="popVisible">
            <ItemShopCart v-if="popTitle == '加入采购车'" v-model="shopCart" @hide="popHide"></ItemShopCart>
        </el-dialog>
    </div>
</template>

<script>
    import _ from 'lodash';
    import QRCode from 'qrcode';
    import {  } from '@/service/company';
    import { IMG_URL_PRE } from '@/config';
    import { addToPurCar,putawayAll } from '@/service/sale';
    import ItemShopCart from '@/views/sale/pur/ItemShopCart.vue';
    export default {
        components: {
            ItemShopCart
        },
        data() {
            return {
                imgUrl:IMG_URL_PRE,
                popVisible: false,
                popTitle: '',
                input2:"",// 搜索内容
                tabList:[
                    {name:"设计师合作款"},
                    {name:"工厂自有款"},
                ],
                tabIndex:"0",
                queryOpt:{
                    page:{
                        pageNumber:1,
                        pageCount:15,
                    },
                    // order: [
                    //     ["desc"]
                    // ],
                },
                total:0,
                shopCart:null,
                shopCartIdx:null,
                colorIndex:0,
                list:null,
                // 查看已发布商品
                lookTaoBao:"https://item.taobao.com/item.htm?id=",
                // 去千牛编辑商品
                qianniu:"https://item.publish.taobao.com/sell/publish.htm?itemId=",
            }
        },
        watch: {
            queryOpt: {
                handler() {
                    this.debounceRefreshItems();
                },
                deep: true
            },
        },
        created() {
            this.debounceRefreshItems = _.debounce(this.refreshItems, 500);
        },
        mounted() {
            this.refreshItems();
        },
        methods: {
            goto(path){
                this.$router.push(path);
            },
            refreshItems() {
                console.log('refreshItems');
                putawayAll(this.queryOpt).then(rst => {
                    if (rst.rows && rst.rows.length) {
                        this.total=rst.count;
                        this.list = rst.rows;
                        this.list.forEach(element => {
                            this.$set(element,'qrcodeUrl',null);
                        });
                    }
                    console.log(rst);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            creatQRCode(item) {
                QRCode.toDataURL(this.lookTaoBao+item.thirdItemId).then(url => {
                    item.qrcodeUrl = url;
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            // 选项卡
            tab(index) {
                this.tabIndex = index;
                console.log(index);
            },
            // 我要采购
            showShopcart(item,index) {
                this.popTitle="加入采购车";
                this.popVisible=true;
                this.shopCart = item;
                this.shopCart.shopCartIdx = index;
                this.shopCart.colorIndex = this.colorIndex;
                // console.log(this.shopCart);
            },
            popHide() {
                this.popVisible=false;
            },
        }
    }
</script>
<style>

</style>
<style scoped src="../style.css"></style>
<style scoped>

    div>>> .Sjzl-table .el-table .cell {
        text-align: center;
    }
    div>>> .el-table .cell {
        text-align: center;
    }
    div>>> .el-table td:first-child .cell {
        text-align: left;
    }
    div>>> .Sjzl-table .el-table .el-table_1_column_5 .cell{
        padding: 0;
    }
    div>>> .Sjzl-table .el-table .el-table_1_column_6 .cell{
        padding: 0;
    }
    div>>> .Sjzl-table .el-table .el-table_1_column_7 .cell{
        padding: 0;
    }
    div>>> .Sjzl-table .el-table .el-table_1_column_8 .cell{
        padding: 0;
    }
    div.container>>> .el-dialog {
        min-width: 650px;
    }
    div>>> .el-input-number--mini {
        width: 90px;
    }
    /*----选项卡----*/
    .tab_menu {
        background: #fff;
        padding: 10px 20px;
        margin: 20px;
    }
    .tab_menu .menu_item{
        height: 70px;
        line-height: 70px;
        margin-right:70px;
        cursor: pointer;
        font-size: 14px;
        color: #808080;
    }
    .tab_menu .menu_item.cur{
        color: #4D4D4D;
        border-bottom: 2px solid #4D4D4D;
    }
    .coverImg {
        padding-right: 20px;
    }
    .coverImg img{
        width: 120px;
        height: 120px;
    }
    .text-name {
        text-align: left;
        font-size: 16px;
        font-weight:bold;
        color: #4D4D4D;
        line-height: 22px;
        margin-bottom: 17px;
    }
    .text-type,.text-company,.text-mi {
        text-align: left;
        font-weight:500;
        font-size: 14px;
        color:rgba(128,128,128,1);
        margin-bottom: 10px;
    }
    .text-mi {
        color: #4D4D4D;
    }
    .text-company:last-child {
        margin-bottom: 0;
    }
    .shop-icon {
        text-align: center;
        height: 35px;
        padding: 6px;
        background: #E6E6E6;
        margin-bottom: 1px;
    }
    .shop-icon:last-child {
        margin-bottom: 0px;
    }
    .shop-icon i {
        font-size: 20px;
        color: #FF5800;
    }


    
</style>